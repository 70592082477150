<!-- 雷达库 -->
<template>
  <div class="wrap bao" id="radar" v-loading="loading">
    <input id="btnCopy" v-model="copyWeChat" />
    <div class="seach">
      <el-button type="warning" plain @click="is_seach = true">筛 选</el-button>
    </div>
    <div class="jianlileida" v-if="newlist.length == 0">暂无雷达简历</div>

    <div class="info_wrap" v-for="(item, index) in newlist" :key="index">
      <div class="avatar">
        <img :src="item.avatar" v-if="item.avatar" alt />
        <!-- <i :class="wayList[item.is_download].icon" :style="{color:wayList[item.is_download].color}"></i> -->
        <!-- 在没有上传图片的时候显示这个 -->
        <div v-else>
          <img
            src="./../../assets/images/test/boy.png"
            alt
            v-if="item.gender && item.gender === '男'"
          />
          <img
            src="./../../assets/images/test/girl.png"
            alt
            v-else-if="item.gender && item.gender === '女'"
          />
          <img
            src="./../../assets/images/test/weizhi.png"
            alt
            v-else
            class="weizhi"
          />
        </div>
        <p
          class="career_progress"
          :style="{
            color: list_1[item.career_progress].color,
            fontWeight: 900,
          }"
        >
          {{
            item.career_progress > 0
              ? list_1[item.career_progress].text
              : "未选择"
          }}
        </p>
      </div>

      <!-- <img v-lazy="item.head" alt :key="item.head" /> -->

      <div class="headSvg">
        <svg-icon
          iconClass="headMan"
          v-if="item.gender && item.gender === '男'"
        ></svg-icon>
        <svg-icon
          iconClass="headWoman"
          v-else-if="item.gender && item.gender === '女'"
        ></svg-icon>
      </div>

      <div>
        <div class="bsaicInfo">
          <div class="name">
            <p :title="item.nick_name.length > 10 ? item.nick_name : ''">
              昵称：{{
                item.nick_name.length > 10
                  ? item.nick_name.slice(0, 10) + "..."
                  : item.nick_name
              }}
            </p>
            <p>丨</p>
            <p>{{ item.age == "保密" ? item.age : item.age + "岁" }}</p>
            <img
              class="imgaa w20"
              v-if="item.source_port == 0 || item.source_port == 3 || item.source_port == 5 || item.source_port == 7"
              src="../../assets/images/tool/weixin.png"
              alt=""
              title="数据来源于微信"
            />
            &nbsp;&nbsp;&nbsp;
            <img
              class="imgaa"
              src="../../assets/images/tool/douyin.png"
              alt=""
              v-if="item.source_port == 1 || item.source_port == 3 || item.source_port == 6 || item.source_port == 7"
              title="数据来源于抖音"
            />
            <img
              class="imgaa"
              src="https://oss.hr24.com.cn/public/0e8f8c86591949348c38dc32549725e8.png?BUCKET=png-bucket"
              alt=""
              v-if="item.source_port == 4 || item.source_port == 5 || item.source_port == 6 || item.source_port == 7"
              title="数据来源于快手"
            />
          </div>

          <p>手机：{{ item.phone }}</p>
          <p>期望职位：{{ item.position_name }}</p>
        </div>

        <div class="expect">
          <el-popover
            placement="bottom"
            width="760"
            trigger="hover"
            :offset="30"
          >
            <el-table :data="item.company_data" height="300">
              <el-table-column
                width="180"
                property="company_name"
                label="浏览公司"
              ></el-table-column>
              <el-table-column width="140" label="浏览职位所在地区">
                <template slot-scope="scope">
                  {{
                    scope.row.company_place_data.city_name +
                    scope.row.company_place_data.district_name
                  }}
                </template>
              </el-table-column>
              <el-table-column
                width="140"
                property="jd_name"
                label="浏览公司职位"
              ></el-table-column>
              <el-table-column
                width="140"
                property="jd_title"
                label="浏览公司职位标题"
              ></el-table-column>
              <el-table-column
                width="100"
                property="browse_time"
                label="浏览时间"
              ></el-table-column>
            </el-table>

            <!-- <el-tooltip class="item" effect="dark" placement="right"> -->
            <!-- :content="item.expect_place_str" -->

            <span slot="reference">
              <p>
                <svg-icon iconClass="site"></svg-icon>
                <span class="fontColor fontPadding">浏览公司所在地区：</span>

                <span>{{ item.company_place_one }}</span>
              </p>
              <p>
                <svg-icon iconClass="post"></svg-icon>
                <span class="fontColor fontPadding">浏览公司：</span>
                {{ item.company_name_one }}
              </p>
              <p>
                <svg-icon iconClass="zhiWei"></svg-icon>
                <span class="fontColor fontPadding">浏览公司职位：</span>
                {{ item.company_data[0].jd_name }}
              </p>
            </span>

            <!-- </el-tooltip> -->
          </el-popover>
        </div>

        <div
          style="clear: both; margin-left: 10px; width: 530px"
          v-if="item.remarks != ''"
        >
          <p>沟通备注：{{ item.remarks }}</p>
        </div>
        <div
          style="clear: both; margin-left: 10px; width: 530px"
          v-if="item.clue_resume_tags.length != 0"
        >
          <p class="resume_p">
            简历标签：<span
              v-for="(tips, idx) in item.clue_resume_tags"
              :key="idx"
              >{{ tips }}</span
            >
          </p>
        </div>
        <div class="radar-btn get-btn">
          <div
            class="comment radar-comment"
            style="background-color: #409eff; margin: 0; text-align: center"
            @click="onresumeVisible(item, index)"
          >
            标签
          </div>
          <div
            class="comment radar-comment"
            @click="
              centerDialogVisible = true;
              info = item;
              remarks = info.remarks;
              index_item = index;
            "
          >
            备注
          </div>

          <el-dropdown
            split-button
            type="text"
            @command="commandHandle"
            :style="{ backgroundColor: statusList[item.status].color }"
          >
            {{ statusList[item.status].type }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ index: index1, item: item }"
                v-for="(item1, index1) in statusList.slice(1)"
                :key="index1"
                >{{ item1.type }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- <div class="radar-btn get-btn" style="top: 63px">
          <div
            class="comment radar-comment"
            style="
              width: 72px;
              background-color: #409eff;
              margin: 0;
              text-align: center;
            "
            @click="onresumeVisible(item)"
          >
            简历标签
          </div>
        </div> -->
        <!-- <i :class="wayList[item.is_download].icon" :style="{color:wayList[item.is_download].color}"></i> -->
      </div>
      <div class="gongneng">
        <p class="icon">
          <el-tooltip
            class="item"
            effect="dark"
            content="复制微信号"
            placement="bottom"
          >
            <span
              :class="[
                'iconfont',
                'iconweixin-copy',
                {
                  disableds:
                    item.source_port === 3 || item.source_port === 0
                      ? false
                      : true,
                },
              ]"
              :style="{
                color:
                  item.source_port === 3 || item.source_port === 0
                    ? '#92c73d'
                    : '#ccc',
              }"
              @click.stop="click_function('weChat', item)"
            ></span>
          </el-tooltip>
        </p>
        <p class="icon">
          <el-tooltip
            class="item"
            effect="dark"
            content="在线沟通"
            placement="bottom"
          >
            <span
              class="iconfont iconliaotianqingqiu"
              :style="{ color: '#ff9900' }"
              @click.stop="click_function('online', item)"
            ></span>
          </el-tooltip>
        </p>
      </div>
      <p class="posi">{{ item.create_time }}</p>
    </div>

    <el-dialog title="筛选" :visible.sync="is_seach" width="500px">
      <el-form label-width="70px" :model="formSeach">
        <!-- <el-form-item label="昵称">
          <el-input v-model="formSeach.name"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="性别">
          <el-select v-model="formSeach.gender" placeholder="请选择性别">
            <el-option label="男" :value="1"> </el-option>
            <el-option label="女" :value="2"> </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="简历状态">
          <el-select v-model="formSeach.state" placeholder="请选择状态">
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.type"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="seachClearClick">清 空</el-button>
        <el-button @click="is_seach = false">取 消</el-button>
        <el-button type="primary" @click="seachClick">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="沟通备注"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
    >
      <textarea class="area" v-model="remarks"></textarea>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ResumeRemark">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="简历标签"
      :visible.sync="resumeVisible"
      @close="dialogclose"
      width="500px"
      center
    >
      <div class="resume_wrap">
        <el-tag
          :key="tag"
          v-for="tag in dynamicTags"
          closable
          :disable-transitions="false"
          @close="resume_handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          style="margin-top: 10px"
          class="input-new-tag"
          maxlength="8"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="resume_handleInputConfirm"
          @blur="resume_handleInputConfirm"
        >
        </el-input>
        <div v-else style="display: inline-block">
          <el-button
            v-if="dynamicTags.length < 2"
            class="button-new-tag"
            size="small"
            @click="resume_showInput"
            >添加</el-button
          >
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="!inputVisible" @click="resume_ok"
          >确 定</el-button
        >
        <div v-else class="el-button el-button--primary">
          <span>确 定</span>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="设置面试时间"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-date-picker
        style="width: 100%"
        v-model="datetime"
        type="datetime"
        value-format="timestamp"
        placeholder="选择面试日期"
        :default-value="newDate"
      ></el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="timeYes">确 定</el-button>
      </span>
    </el-dialog>
    <div v-if="!loading && index_conent > newlist.length" class="bottom">
      <span> <i class="el-icon-loading"></i>正在加载 </span>
    </div>
    <WeChat :id="resume_id" v-if="is_show" @close="xiaohui"></WeChat>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getRadar,
  changeRadarRemarks,
  changeRadarStatus,
  setting_interview,
  group_chat_record,
  set_clue_tags,
} from "@/api/tool";
import { getResumeBankData, copy_wechat_id } from "@/api/resume";
import WeChat from "@/components/weChat";
let id = "";
let index = "";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    WeChat,
  },
  data() {
    //这里存放数据
    return {
      loading: true,
      list: [],
      statusList: [
        { color: "#ccc", type: "未标记", id: 0 },
        { color: "#F56C6C", type: "不合适", id: 1 },
        { color: "#E6A23C", type: "未接通", id: 2 },
        { color: "#67C23A", type: "可面试", id: 3 },
      ],
      wayList: [
        { icon: "iconfont iconfenxiang", color: "#67c23a" },
        {
          icon: "iconfont iconxiazai",
          color: "#67c23a",
        },
      ],
      //沟通备注
      centerDialogVisible: false,
      remarks: "",
      info: {},
      // 简历标签
      resumeVisible: false,
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      resume_index_item: 0,
      // 简历标签↑
      page: 1,
      switchForMore: false,
      index_item: 0,
      index_conent: 0,
      datetime: "",
      dialogVisible: false,
      id_id: "",
      resume_id: "",
      is_show: "",
      copyWeChat: "",
      index_list: 0,
      list_1: [
        {
          id: 0,
          text: "未选择",
          color: "#9a9a9a",
        },
        {
          id: 1,
          text: "急需找工作",
          color: "#f00",
        },
        {
          id: 2,
          text: "随便看看",
          color: "#e2a826",
        },
        {
          id: 3,
          text: "考虑机会",
          color: "#1296db",
        },
        {
          id: 4,
          text: "暂不考虑",
          color: "#9a9a9a",
        },
      ],
      is_seach: false,
      formSeach: {},

      // 点击简历标签存储的ID
      resume_elite_id: "",
    };
  },
  //监听属性 类似于data概念
  computed: {
    newlist() {
      return this.list.filter((item) => {
        if (item.province != "" && item.city != "") {
          item.home = item.province + item.city;
        } else {
          item.home = "未获取";
        }
        item.company_name_one = "";
        item.company_place_one = "";
        item.company_data.filter((v, i) => {
          if (i < 1) {
            item.company_name_one = v.company_name;
            item.company_place_one =
              v.company_place_data.city_name +
              v.company_place_data.district_name;
          } else {
            return;
          }
        });
        return item;
      });
    },
    newDate() {
      let dd = new Date();
      var s = dd.getSeconds();
      return new Date().getTime() - s * 1000;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    resume_ok() {
      let page_index = Math.ceil((this.resume_index_item + 1) / 15);
      this.loading = true;
      set_clue_tags({
        elite_id: this.resume_elite_id,
        resume_tags: this.dynamicTags.toString(),
      }).then((res) => {
        if (!res.code) {
          this.resumeVisible = false;
          this.resume_elite_id = "";
          this.dynamicTags = [];
          this.$message.success(res.msg);
          getRadar({ page: page_index, key: {} }).then((res) => {
            if (res.code === 0) {
              let arr = this.list;
              console.log("chushi", arr);
              arr.splice(
                (page_index - 1) * 15,
                res.data.radar_list.length,
                ...res.data.radar_list
              );
              this.list = arr;
              console.log("genggai ", arr);
              this.loading = false;
              this.index_conent = res.data.radar_count;
            }
          });
        }
      });
    },
    onresumeVisible(item, index) {
      this.resume_index_item = index;
      this.resumeVisible = true;
      this.resume_elite_id = item.elite_id;
      this.dynamicTags = JSON.parse(JSON.stringify(item.clue_resume_tags));
    },
    resume_handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    resume_showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    dialogclose() {
      console.log("111");
      this.dynamicTags = [];
    },

    resume_handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    selectText(id) {
      this.$nextTick(() => {
        // 必须把输入框类型转化为 text，否则无法选取。（ERROR：selectionStart/selectionEnd on input type=“number” no longer allowed in Chrome）
        const obj = document.getElementById(id);
        obj.select();
        document.execCommand("Copy", false, null);
        if (document.execCommand("Copy", false, null)) {
          this.$message.success("复制成功");
        }
      });
    },

    xiaohui() {
      const body = document.querySelector("body");
      var box = document.getElementsByClassName("weChat")[0];
      if (box) {
        body.removeChild(box);
      }
    },

    click_function(type, item) {
      if (type == "weChat") {
        // document.removeEventListener('copy')
        copy_wechat_id({ resume_id: item.elite_id }).then((res) => {
          console.log(res);
          if (res.code === 110120) {
            this.$message.warning("该简历暂未获取微信号，请换一种方式获取");
          }
          if (res.code === 0) {
            // console.log('复制')
            this.copyWeChat = res.data;
            this.selectText("btnCopy");
          }
        });
      } else if (type == "online") {
        this.is_show = false;
        this.$nextTick(() => {
          this.is_show = true;
        });
        this.resume_id = item.elite_id;
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    timeYes() {
      let _this = this;
      function o() {
        changeRadarStatus({
          elite_id: id,
          status: index,
        }).then((res) => {
          if (res.code === 0) {
            console.log(_this.list[_this.index_list]);
            _this.list[_this.index_list].status = 3;
          }
        });
      }
      console.log(this.datetime);
      if (!this.datetime) {
        this.$message.warning("请选择面试时间");
        return false;
      }

      setting_interview({
        resume_id: this.id_id,
        interview_time: Math.floor(this.datetime / 1000),
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.datetime = "";
          this.$message.success("已添加到面试管理");
          this.dialogVisible = false;

          o();
        }
      });
    },
    //沟通备注
    ResumeRemark() {
      console.log(this.info, this.index_item);
      let page_index = Math.ceil((this.index_item + 1) / 15);
      this.loading = true;
      changeRadarRemarks({
        elite_id: this.info.elite_id,
        remarks: this.remarks,
      }).then((res) => {
        if (res.code === 0) {
          console.log(page_index);
          let obj = { page: page_index, key: {} };
          if (JSON.stringify(this.formSeach) != "{}") {
            obj.key = this.formSeach;
          }
          getRadar(obj).then((res) => {
            if (res.code === 0) {
              let arr = this.list;
              console.log("chushi", arr);
              arr.splice(
                (page_index - 1) * 15,
                res.data.radar_list.length,
                ...res.data.radar_list
              );
              this.list = arr;
              console.log("genggai ", arr);
              this.loading = false;
              this.index_conent = res.data.radar_count;
            }
          });
          this.centerDialogVisible = false;
        }
      });
      console.log(this.remarks);
    },
    commandHandle(value) {
      this.id_id = value.item.elite_id;
      console.log(value);
      if (value.index == 2) {
        let dd = new Date();
        var s = dd.getSeconds();
        id = value.item.elite_id;
        value.item.interview_time
          ? (this.datetime = value.item.interview_time * 1000)
          : (this.datetime = "");

        index = value.index + 1;
        this.list.map((item, index) => {
          if (item.id == value.item.id) {
            this.index_list = index;
          }
          return item;
        });
        this.dialogVisible = true;
      } else {
        console.log("chsabchjas");
        changeRadarStatus({
          elite_id: value.item.elite_id,
          status: value.index + 1,
        }).then((res) => {
          if (res.code === 0) {
            console.log("今日");
            this.list.map((item, index) => {
              if (item.id == value.item.id) {
                this.index_list = index;
                item.status = value.index + 1;
              }
              return item;
            });
            console.log(this.list[index1]);
          }
        });
      }

      console.log(value);
    },
    // load() {
    //   this.switchForMore = true
    //   getRadar({ page: this.page }).then((res) => {
    //     if (res.code === 0) {
    //       this.list.push(...res.data.radar_list)
    //       // this.loading = false
    //     }
    //   })
    //   console.log('页面到底', this.page)
    //   this.page++
    // },
    scrollHandle() {
      let index_is = true;
      let dom = document.getElementById("radar");
      let dom_sollet =
        window.innerHeight -
        (dom.offsetTop - window.pageYOffset) -
        dom.offsetHeight;
      // console.log('页面距离aa', dom_sollet)
      if (dom_sollet >= 0 && index_is) {
        index_is = false;
        console.log("页面到底", `${index_is}$`);
        window.removeEventListener("scroll", this.scrollHandle);
        this.loading = true;
        let obj = { page: this.page, key: {} };
        if (JSON.stringify(this.formSeach) != "{}") {
          obj.key = this.formSeach;
        }
        getRadar(obj).then((res) => {
          if (res.code === 0) {
            this.list.push(...res.data.radar_list);
            this.loading = false;
            index_is = true;
            console.log("下拉成功", "ac");
            this.index_conent = res.data.radar_count;
            this.$nextTick(() => {
              window.addEventListener("scroll", this.scrollHandle);
            });
          }
        });
        this.page++;
      }
    },
    //筛选确定
    seachClick() {
      this.is_seach = false;
      this.page = 1;
      let obj = { page: this.page, key: {} };
      if (JSON.stringify(this.formSeach) != "{}") {
        obj.key = this.formSeach;
      }
      getRadar(obj).then((res) => {
        if (res.code === 0) {
          this.list = res.data.radar_list;
          this.loading = false;
          this.is_seach = false;
          this.index_conent = res.data.radar_count;
        }
      });
      this.page++;
    },
    //筛选清空
    seachClearClick() {
      this.formSeach = {};
      this.is_seach = false;
      getRadar({ page: 1, key: {} }).then((res) => {
        if (res.code === 0) {
          this.list = res.data.radar_list;
          this.is_seach = false;
          this.index_conent = res.data.radar_count;
          this.loading = false;
        }
      });
      this.page++;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    getRadar({ page: 1, key: {} }).then((res) => {
      if (res.code === 0) {
        this.list = res.data.radar_list;
        this.index_conent = res.data.radar_count;
        this.loading = false;
      }
    });
    this.page++;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    window.addEventListener("scroll", this.scrollHandle);
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {
    console.log("雷达销毁");
    this.xiaohui();
    window.removeEventListener("scroll", this.scrollHandle);
  }, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.weizhi {
  border: 1px solid #ccc;
}
.jianlileida {
  text-align: center;
  margin-top: 100px;
  color: #ccc;
}
.wrap {
  // margin-top: 80px;
  min-height: 80vh;
  @include padding;
  min-width: 1100px;
  padding-bottom: 0;
  padding-top: 0px;
}
.info_wrap {
  width: 1000px;
  // height: 80px;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 20px 20px;
  color: $jd_fontColor;
  font-size: $jd_fontSize;
  /* text-align: center; */
  /* line-height: 200px; */
  display: flex;
  position: relative;
  cursor: pointer;
  position: relative;
  border-bottom: rgb(233, 233, 233) solid 2px;
}
.info_wrap:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.posi {
  position: absolute;
  bottom: 5px;
  right: 10px;
}

.fontPadding {
  padding-left: 10px;
}
p {
  margin: 0;
  line-height: 27px;
}

img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.headSvg {
  position: relative;
  left: -10px;
  top: 0;
  z-index: 50;
}
.imgaa {
  display: block;
  width: 18px;
  height: 18px;
  float: left;
  border-radius: 0px;

  margin: 4px 4px 0 0px;
}
.w20 {
  width: 20px !important;
  height: 20px !important;
}
.name {
  overflow: hidden;
}
.name > P {
  float: left;
  margin-right: 4px;
}
/*基本信息 */
.bsaicInfo {
  margin-left: 10px;
  float: left;
  width: 230px;

  /* background-color: red; */
}
// .selfAppraisal {
//   /* 多行超出隐藏 */
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 3;
//   overflow: hidden;
//   height: 57px;
//   height: 48px\0;
// }
/* 期望 */
.expect {
  float: left;

  width: 300px;
}
.expect p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.radar-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.radar-btn + i {
  position: absolute;
  bottom: 20px;
  right: 10px;
}
.radar-comment {
  margin-left: 7px;
  float: left;
}
.head {
  font-size: 50px;
  color: brown;
}
.is-down {
  width: 50px;
  height: 24px;
  vertical-align: middle;
}
.area {
  border: 1px solid #ff9900;
  width: 450px;
  height: 100px;
  min-width: 450px;
  max-width: 450px;
  font-size: 16px;
  color: $selfColor;
  // text-align: center;
  // margin: 0 auto;
  display: inline-block;
  font-family: auto;
}
.area:focus {
  outline: none !important;
  border: 1px solid #719ece;
  box-shadow: 0 0 10px #719ece;
}
.bottom {
  text-align: center;
  color: #ccc;
}
.bao /deep/ .dialog-footer .el-button--primary {
  background: #ff9900;
  border-color: #ff9900;
}
.icon {
  line-height: 97px;
  margin: 0 14px;
}
.icon > span {
  cursor: pointer;
  font-size: 30px;
}
.gongneng {
  display: flex;
}
#btnCopy {
  height: 0px;
  // width: 0px;
  opacity: 0;
}
.career_progress {
  text-align: center;
}
.seach {
  text-align: right;
  padding: 12px 50px;
}
.disableds {
  pointer-events: none;
  color: grey;
}
.resume_wrap /deep/ .el-tag {
  margin-right: 10px;
}
.resume_p span {
  padding: 4px 8px;
  font-size: 14px;
  margin: 0 5px;
  color: #fff;
  background-color: #f0f9eb;
  color: #67c23a;
  border: 1px solid #e1f3d8;
}
</style>
